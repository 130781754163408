import {Fragment, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom';

import Loader from './components/Loader/Loader';
import Header from './layouts/Header/Header';
import Pages from './Pages';
import Footer from './layouts/Footer/Footer';

import './App.scss';



export default function App() {
  const [mediaLoading, setMediaLoading] = useState(true);
  const {pathname} = useLocation();

  useEffect( () => {
    if ( document.readyState === 'complete' ) setMediaLoading(false);
    window.addEventListener('load', () => setMediaLoading(false) );
  }, []);

  useEffect( () => {
    window.scrollTo({ top: 0 })
  }, [pathname])


  return (
    <Fragment>

      <Header />
      { mediaLoading ? <Loader type="preloader" /> : false }
      <Pages />
      <Footer />

    </Fragment>
  );
}
