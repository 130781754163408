import React from 'react';

import './contacts-page.scss';



export default function ContactsPage(props){
  return(
    <div className="page contacts-page">



    </div>
  )
}