import {Card} from 'antd';
import Image from '../../../../../../components/Image/Image';

import './offer-card.scss';



export default function OfferCard(props){
  return (
    <Card 
      className={'offer-card' + (props.className ? ` ${props.className}` : '')}
      hoverable
      cover={<Image src={props.img} alt='offer-img' className="offer-card__image-component" />}
      actions={props.actions}
    >

      <div className="offer-card__title">{ props.title }</div>
      <div className="offer-card__content">{props.content}</div>

    </Card>
  )
}