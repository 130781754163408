import MainSection from './components/MainSection/MainSection';
import Offers from './components/Offers/Offers';

import './home-page.scss';


export default function HomePage(props){
  return (
    <div className="page home-page">

        <MainSection className="home-page__main-section" />
        <Offers className="home-page__offers" />

    </div>
  )
}