import {Fragment} from 'react';
import {Card} from 'antd';


import Image from '../../../../components/Image/Image';

import trainerCardBg from './images/trainer-card-bg.png';

import 'antd/dist/antd.css';
import './trainer-card.scss';



export default function TrainerCard(props){
  return (
    <a
      className={props.className ? props.className : ''} 
      href={props.link} target="_blank" rel="noreferrer"
    >
      <Card
        className="trainer-card" hoverable size="small"
        cover={
          <Fragment>
            <img src={trainerCardBg} alt="trainer-card-bg" className="trainer-card__cover-bg" />
            <Image src={props.img} alt={props.alt} className="trainer-card__image-component" />
          </Fragment>
        }
      >

        <span className="trainer-card__title">{props.title}</span>
        
      </Card>
    </a>
  )
}