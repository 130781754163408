import {GridLoader} from 'react-spinners';
import './loader.scss';


export default function Loader(props){
  return(
    <div className={ (props.type === 'preloader' ? 'preloader' : 'loader') + (props.className ? ` ${props.className}` : '') } >

      <GridLoader color="#3333ff" />

    </div>
  )
}