import TrainerCard from './components/TrainerCard/TrainerCard';

import mainTrainerImg from './images/main-trainer.png';
import abacusExampleImg from './images/abacus-example.png';
import abacusImg from './images/abacus.png';
import fleshCardsImg from './images/flesh-cards.png';
import countImg from './images/count.png';
import schulteTableImg from './images/schulte-table.png';
import paintImg from './images/paint.png';

import 'antd/dist/antd.css'
import './trainers-page.scss';



export default function TrainersPage(props){
  return(
    <div className="page trainers-page">

      <TrainerCard
        className="main-trainer-card"
        title="Тренажёр" link="/trainers/main-trainer/"
        img={mainTrainerImg} alt="main-trainer-img"

      />

      <TrainerCard
        title="Знакомство с абакусом" link="/trainers/abacus-example/"
        img={abacusExampleImg}
      />
      <TrainerCard 
        title="Абакус тренажёр" link="/trainers/abacus/"
        img={abacusImg}
      />
      <TrainerCard 
        title="Флеш карты" link="/trainers/flesh-cards/"
        img={fleshCardsImg}
      />
      <TrainerCard
        title="Сосчитайка" link="/trainers/count/"
        img={countImg}
      />
      <TrainerCard 
        title="Таблица шульте" link="/trainers/schulte-table/"
        img={schulteTableImg}
      />
      <TrainerCard 
        title="Учимся рисовать" link="/trainers/paint/"
        img={paintImg}
      />

    </div>
  )
}