import telegramImg from './images/telegram.svg';
import './SocialButton.scss';



export default function SocialButton(props){
  return(
    <a
      href="https://t.me/childrenplanet" target="_blank" rel="noreferrer"
      className={'social-button' + (props.className ? ` ${props.className}` : '')}
    >

      <img className="social-button__icon" src={telegramImg} alt="icon" />

    </a>
  )
}