import {Link} from 'react-router-dom';
import {Result, Button} from 'antd';

import 'antd/dist/antd.css';
import './not-found-page.scss';



export default function NotFoundPage(props){
  return (
    <div className="page not-found-page">

      <Result 
        status="404" title="Страница не найдена"
        extra={<Link to="/"> <Button type="primary">На главную</Button> </Link>}
      />

    </div>
  )
}