import bg from './images/bg.png';
import img from './images/img.png';

import './main-section.scss';



export default function MainSection(props){
  return(
    <div className={'main-section' + (props.className ? ` ${props.className}` : '')}>

      <div className="main-section__content">
        <h1>Children Planet</h1>
        <p>учебно познавательная онлайн платформа</p>
      </div>
      
      <img src={img} alt="main-img" className="main-section__img" />
      <img src={bg} alt="section-bg" className="main-section__bg" />

    </div>
  )
}