import {Switch, Route} from 'react-router-dom';
import HomePage from './views/HomePage/HomePage';
import TrainersPage from './views/TrainersPage/TrainersPage';
import ContactsPage from './views/ContactsPage/ContactsPage';
import ProfilePage from './views/ProfilePage/ProfilePage';
import NotFoundPage from './views/NotFoundPage/NotFoundPage';


export default function Pages(props){
  return (
    <Switch>

      <Route exact path="/" component={HomePage} />
      <Route exact path="/trainers/" component={TrainersPage} />
      <Route path="/contacts/" component={ContactsPage} />
      <Route path="*" component={NotFoundPage} />

    </Switch>
  )
}