import {Typography} from 'antd';
import {
  PhoneOutlined,
  SendOutlined,
  YoutubeOutlined
} from '@ant-design/icons';
import SocialButton from './components/SocialButton/SocialButton';

import './footer.scss';



export default function Footer(props){
  return(
    <footer className="footer">
      <SocialButton className="footer__social-button" />
      
      <div className="footer-contacts">
        <div className="footer-contacts__title">Наши контакты</div>

        <div className="footer-contact-part">
          <PhoneOutlined className="footer-contact-part__icon" />
          +998-94-404-85-75
        </div>
        <div className="footer-contact-part">
          <SendOutlined className="footer-contact-part__icon" rotate={-45} />
          <Typography.Link href="https://t.me/gulsana_7" target="_blank" rel="noreferrer">
            Telegram
          </Typography.Link>
        </div>
        <div className="footer-contact-part">
          <SendOutlined className="footer-contact-part__icon" rotate={-45} />
          <Typography.Link href="https://t.me/zarina_71" target="_blank" rel="noreferrer">
            Telegram
          </Typography.Link>
        </div>
      </div>


      <div className="footer-social">
        <div className="footer-social__title" >Мы в соц.сетях</div>

        <div className="footer-social-part">
          <SendOutlined className="footer-social-part__icon" rotate={-45} style={{ color : 'skyblue' }} />
          <Typography.Link href="https://t.me/childrenplanet" target="_blank" rel="noreferrer">
            Telegram
          </Typography.Link>
        </div>
        <div className="footer-social-part">
          <YoutubeOutlined className="footer-social-part__icon" style={{ color : '#ff2222' }} />
          <Typography.Link href="https://www.youtube.com/channel/UCM3W9q47EmSeisYdHND_8Kg" target="_blank" rel="noreferrer">
            Youtube
          </Typography.Link>
        </div>
      </div>

    </footer>
  )
}