import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {Menu, Button} from 'antd';
import {
  MenuOutlined,
  HomeFilled, 
  CalculatorFilled,
  ReadFilled,
  ShoppingFilled,
  MessageFilled
} from '@ant-design/icons';

import 'antd/dist/antd.css';
import './header.scss';



export default function Header(props){
  const [visible, setVisible] = useState(false);
  const {pathname} = useLocation();

  useEffect( () => {
    window.addEventListener('click', e => {
      if ( !e.target.closest('header') ) setVisible(false)
    })
  }, []);


  return(
    <header>
      <Button
        className="toggle-menu-button"
        type="primary" size="small" onClick={ () => setVisible(!visible) }
      > 
        <MenuOutlined />
      </Button>


      <Menu 
        className={'navigation' + ( visible ? ' navigation_visible' : '' )}
        mode="vertical" theme="dark" selectedKeys={[pathname]} onClick={ () => setVisible(false) }
      >
        <Menu.Item key="logo" className="navigation__logo">
          <Link to="/"> Children Planet </Link>
        </Menu.Item>

        <Menu.Item key="/" icon={<HomeFilled />}>
          <Link to="/"> Главная </Link>
        </Menu.Item>

        <Menu.Item key="/trainers/" icon={<CalculatorFilled />}>
          <Link to="/trainers/"> Тренажёры </Link>
        </Menu.Item>

        <Menu.Item key="blog" icon={<ReadFilled />}> 
          <a href="https://blog.children-planet.ru" target="_blank" rel="noreferrer"> Блог </a>
        </Menu.Item>

        <Menu.Item key="shop" icon={<ShoppingFilled />}>
          <a href="https://blog.children-panet.ru/tag/shop/" target="_blank" rel="noreferrer"> Магазин </a>
        </Menu.Item>

        {
          // <Menu.Item key="/contacts/" icon={<MessageFilled />}>
          //   <Link to="/contacts/"> Контакты </Link>
          // </Menu.Item>
        }
      </Menu>
    </header>
  )
}