import {Link} from 'react-router-dom';
import {Tooltip} from 'antd';
import OfferCard from './components/OfferCard/OfferCard';

import mentalImg from './images/mental.png';
import trainersImg from './images/trainers.png';
import shopImg from './images/shop.png';

import './offers.scss';



export default function Offers(props){
  return (
    <div className={'offers' + (props.className ? ` ${props.className}` : '')} >

      <a href="https://blog.children-planet.ru" rel="noreferrer" target="_blank">
        <OfferCard
          title='Ментальная арифметика' img={mentalImg}
          content={
            `Всё для обучения и развития ваших детей и учеников : 
            видео уроки, флеш-карты, интерактивные игры и другое` 
          }
          actions={[
            <Tooltip title="Перейти в блог" key="blog">
              Открыть
            </Tooltip>
          ]}
        />
      </a>

      <Link to="/trainers/">
        <OfferCard
          title='Онлайн тренажёры' img={trainersImg}
          content={
            `Онлайн тренажёры для интерактивных занятий.
            Уникальные тренажёры и мини игры для мент. арифметики, скорочтения и мнемоники`
          }
          actions={[
            <Tooltip title="Перейти в тренажёры" key="trainers">
              Открыть
            </Tooltip>
          ]}
        />
      </Link>

      <a href="https://blog.children-planet.ru/tag/shop/" target="_blank" rel="noreferrer">
        <OfferCard
          title="Интернет магазин" img={shopImg} 
          content={
            `Обучающие игровые пособия для тренировки, развития мозга и товары для геймификации процесса обучения`
          }
          actions={[
            <Tooltip title="Перейти в магазин" key="shop">
              Открыть
            </Tooltip>
          ]}
        />
      </a>

    </div>
  )
}