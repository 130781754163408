import {useState} from 'react';
import {Spin} from 'antd';

import 'antd/dist/antd.css';
import './image.scss';



export default function Image(props){
  const [loading, setLoading] = useState(true);

  return (
    <div className={'image-component' + (props.className ? ` ${props.className}` : '')}>

      { loading ? <div className="image-component__loader"> <Spin size="large" /> </div> : ''}
      <img src={props.src} alt={props.alt} onLoad={ () => setLoading(false) } />

    </div>
  )
}